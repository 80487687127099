// import * as types from './types'
import util from '@/assets/js/utils';

import room_state from './room_state';


const mutations = {

  /**
   * 通用 更改store state的值
   * @param  {[type]} state [description]
   * @param  {[type]} data  [description]
   * @return {[type]}       [description]
   */
  changeState(state, data) {
    console.log(data);
    state[data.key] = data.val;
  },

  changeMate(state, data) {
    state.mate = data;
  },
  /**
   * 更改媒体库 state的值
   * @param  {[type]} state [description]
   * @param  {[type]} data  [description]
   * @return {[type]}       [description]
   */
  changeMediaGroup(state, data) {
    state.media[data.type].group = data.val;
  },

  // /**
  //  * 更改媒体库 state的值
  //  * @param  {[type]} state [description]
  //  * @param  {[type]} data  [description]
  //  * @return {[type]}       [description]
  //  */
  // changeMediaList(state, data) {
  //     state.media[data.type][data.id].list = data.val;
  // },

  /**
   * 更改品牌管理状态
   * @param  {[type]} state [description]
   * @param  {[type]} data  [description]
   * @return {[type]}       [description]
   */
  changeManageState(state, data) {
    // console.log(data);
    state.manageState = data;
  },

  /**
   * 记录token到vuex
   * @param  {[type]} state [description]
   * @param  {[type]} data  [description]
   * @return {[type]}       [description]
   */
  tokenToVuex(state, data) {
    const token = util.getStorage('token');
    if (token != undefined) {
      state.token = token;
    }
  },
  /**
   * 同步用户信息到vuex
   * @param  {[type]} state [description]
   * @param  {[type]} data  [description]
   * @return {[type]}       [description]
   */
  userToVuex(state, data) {
    const user = util.getStorage('user');
    if (user != undefined) {
      state.user = JSON.parse(user);
    }
  },

  /**
   * 帐号登录 记录到 VUEX & Local
   * @param  {[type]} state [description]
   * @param  {[type]} data  [description]
   * @return {[type]}       [description]
   */
    localLoginToVuex(state, data) {
    const token = util.getStorage('token');
    if (token != undefined) {
      state.token = token;
    }
    const user = util.getStorage('user');
    if (user != undefined) {
      state.user = JSON.parse(user);
    }
    const brands = util.getStorage('brands');
    if (brands != undefined) {
      state.brands = brands;
    }

    const manageState = util.getStorage('manageState');
    if (manageState != undefined) {
      state.manageState = manageState;
    }
  },

  /**
   * 退出登录 清空 VUEX & Local
   * @param  {[type]} state [description]
   * @return {[type]}       [description]
   */
  logout(state) {
    // util.removeAllStorage();
    util.removeStorage('token');
    util.removeStorage('user');
    util.removeStorage('brands');
    util.removeStorage('manageState');


    state.token = '';
    state.user = '';
    state.brands = '';
    state.manageState = '';

  },

  // 更新直播间ID
  SET_ROOM_ID(state, value) {
    state.roomId = value;
  },
  // 更新直播间昵称
  UPDATE_ROOM_NAME(state, value) {
    state.roomName = value;
  },
  // 更新用户信息
  UPDATE_USER_INFO(state, data) {
    state.userInfo = data;
  },
  // 设置sdkAppId信息
  SET_APP_INFO(state, data) {
    state.appInfo = data;
  },
  // 更新直播间阶段
  UPDATE_LIVE_STAGE(state, value) {
    state.liveStage = value;
  },
  // 更新是否在屏幕分享中
  UPDATE_IS_SCREEN_SHARING(state, value) {
    state.isScreenSharing = value;
  },
  // 更新使用的摄像头设备
  UPDATE_ACTIVE_CAMERA(state, device) {
    state.activeCamera = device;
  },
  // 更新使用的麦克风设备
  UPDATE_ACTIVE_MICROPHONE(state, device) {
    state.activeMicrophone = device;
  },
  // 更新使用的扬声器设备
  UPDATE_ACTIVE_SPEAKER(state, device) {
    state.activeSpeaker = device;
  },
  // 更新是否设置本地流镜像
  UPDATE_SET_MIRROR(state, value) {
    state.isSetMirror = value;
  },
  // 更新是否设置本地流镜像
  UPDATE_OPEN_BEAUTY(state, value) {
    state.isOpenBeauty = value;
  },
  // 更新视频参数
  UPDATE_VIDEO_PROFILE(state, data) {
    console.log(state);
    console.log(data);
    console.log('1111');
    state.videoProfile = data;
  },
  // 更新美颜参数
  UPDATE_BEAUTY_PARAM(state, data) {
    state.beautyParam = data;
  },
  // 更新是否录制直播
  UPDATE_RECORD_LIVE(state, value) {
    state.isRecordLive = value;
  },
  // 更新视频mute状态
  UPDATE_VIDEO_STATE(state, value) {
    state.isVideoMuted = value;
  },
  // 更新音频mute状态
  UPDATE_AUDIO_STATE(state, value) {
    state.isAudioMuted = value;
  },
  // 更新音量大小
  UPDATE_AUDIO_LEVEL(state, value) {
    state.audioLevel = value;
  },
  // 更新上行网络等级
  UPDATE_UPLINK_NETWORK_LEVEL(state, value) {
    state.uplinkQualityLevel = value;
  },
  // 更新直播间人员列表
  UPDATE_MEMBER_LIST(state, value) {
    state.memberList = value;
  },
  // 重置STATE
  RESET_STATE: (state) => {
    console.log("重置")
    Object.assign(state, room_state)
    console.log(room_state)

  },

};
export default mutations;
