import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/index';
import store from '@/vuex/index';

import axios from 'axios'; // 请求管理器
Vue.prototype.$axios = axios;

/* vue meta */
import Meta from 'vue-meta';
Vue.use(Meta);


/* iview */
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
// import '@/assets/css/theme.less';
Vue.use(ViewUI);

// iconfont 图标库
import '@/assets/icon/iconfont.js';
// iconfont
import icon_svg from '@/components/icon';
Vue.component('icon-svg', icon_svg); // 引入 api

/* css */
import '@/assets/css/reset.css';
import '@/assets/css/base.css';
import '@/assets/css/common.css';


/* api */
import api from '@/api/api.js';
Vue.prototype.$api = api;

Vue.prototype.$window = window


/* 公共方法 */
import util from '@/assets/js/utils.js';
Vue.prototype.$util = util;

/* 动画库 */
import animate from 'animate.css';
Vue.use(animate);

/* Qrcode */
import QRcode from '@/components/qrcode/qrcode'
Vue.component('QRcode', QRcode); // Qrcode

/* copy */
import copy from '@/components/mash-copy/mash-copy'
Vue.component('copy', copy); // 引入 api


import eventBus from 'utils/_eventBus.js';
import 'assets/icons';


/* 数字动画 */
import AnimateNumber from 'vue-animate-number' 
Vue.use(AnimateNumber)

Vue.prototype.$eventBus = eventBus;
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
