import Vue from 'vue';
import Router from 'vue-router';
import store from '@/vuex/index'; // vuex store
// import ViewUI from 'view-design';
// import api from "@/api/api.js"; // 引入 api
import util from '@/assets/js/utils';

// 页面
import routes from './routes.js';


// Vue.use(ViewUI);
Vue.use(Router);

const router = new Router({
  mode: 'history',
  // scrollBehavior,
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // iview 页面加载条
  // ViewUI.LoadingBar.start();
  console.log(to);
  console.log(from);
  if (to.meta.title) {
      store.commit('changeMate', {
        title: to.meta.title,
      });
  }
  let userAgent = navigator.userAgent.toLowerCase()
  if ( userAgent.indexOf('electron') > -1 ){
    
    if(to.meta.box == 'sign') {
        let data = {
          'type':'set',
          'size':{
            w:920,
            h:480
          }
        }
        window.set.resize(data);
    } 
    // else if(to.meta.box == 'index') {
    //     let data = {
    //       'type':'set',
    //       'size':{
    //         w:860,
    //         h:460
    //       }
    //     }
    //     window.set.resize(data);
    // } 
    if(to.meta.box == 'main') {
        let data = {
          'type':'set',
          'size':{
            w:1200,
            h:620
          }
        }
        window.set.resize(data);
    }
  }

  if (to.name == 'index') { // 如果VUEX里面没有USER信息 尝试从cookie中同步
    util.setStorage('manageState', false);
    store.commit('localLoginToVuex');
  }
  
  if (!store.state.token) { // 如果VUEX里面没有USER信息 尝试从cookie中同步
    if (util.getStorage('token')) { // 判断cookie里是否包含user信息 如果有则同步
      store.commit('localLoginToVuex');
    }
  }

  // 需要登录 未登录情况下 转跳登陆页面
  if (!store.state.token && to.meta.auth) {
    console.log('跳转登录');
    next({
      name: 'signin', // 跳转到登录页
    });
  }else if(store.state.token && !to.meta.auth) {
    console.log('已经登录 直接跳转首页');
    next({
      name: 'index', // 直接跳转首页
    });
  } else {
    next();
  }
});

router.afterEach(() => {
  // ViewUI.LoadingBar.finish();
});

export default router;
