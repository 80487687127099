<template>
    <div class="comp-copy">
        <div class="content">
            {{content}}
        </div>
        <Button type="primary"  shape="circle" @click="copy(content)">复制链接</Button>
    </div>
</template>

<script>
export default {
    name: 'MashPushMashCopy',
    props: {
        content: {
            type: String,
        },
    },
    data() {
        return {
        };
    },
    mounted() {
        
    },
    methods: {
        copy(data) {
            // 创建input框
            var input = document.createElement("input");
            input.value = data;  // 将调用方法穿入进来的值赋给input
            document.body.appendChild(input);  // 将input添加到body中
            input.select(); // 选中文本
            document.execCommand("copy");  // 调用浏览器复制方法、复制成功
            this.$Message.success('复制成功!');
            input.remove();  // 成功后删除input，防止影响其他元素

        },
    },
};
</script>

<style lang="scss" scoped>
    .comp-copy {
        width: 80%;
        margin: auto;
        display: flex;
        height: 40px;
        line-height: 40px;
        background-color: rgba($color: #000, $alpha: 0.1);
        border-radius: 100px;
        position: relative;
    }
    .comp-copy .content {
        padding:  0 15px;
        color:rgb(145, 145, 145);
    }
    .comp-copy .ivu-btn {
        position: absolute;
        right: 4px;
        top: 4px;
    }
</style>