<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>


export default {
  data() {
    return {
    };
  },
  metaInfo() {
    return {
      title: this.$route.meta.title+'-码尚直播',
      titleTemplate: '%s',
    };
  },
  computed: {

  },
  components: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="less">
</style>
