/**
 *
 * http配置
 */

import axios from 'axios';
import store from '@/vuex/index';
import * as types from '@/vuex/types';
import router from '@/router/index';

// axios 配置
axios.defaults.timeout = 500000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;application/json';
// axios.defaults.headers.post['Authorization'] = 'QcCsTbg3hXvB%2fcfkukVXv75bDAcA9d%2fmI5aHwK%2bjXp6bX7SwYoHLhNw9NNYaVtEpwqBxxpdBWWPG%2fB%2b6R4PlPVM%2bhYMnYJc2PhglB%2bSFBBGNUxtqQ2fqI3CsYLSfK5JHCxTyJgHSju00HZuobLtYOZ1cWUoPqvwmEIC9Y3f%2fotIURR0U4bd%2fdCjB2DTZtTwtw%2fJ10Rt%2bChJmy30Nl2ymSZU%2b0ePSl3v1MRENg57HPVY%3d';

// axios.defaults.headers.post['Authorization'] = store.state.token;

// axios.defaults.headers.post['Platform'] = store.state.platform;

// if(store.state.manageState){
//   console.log('使用店铺token')
//   axios.defaults.headers.post['Authorization'] = store.state.shopToken[store.state.manageState];
// } else {
//   console.log('使用用户token')
//   axios.defaults.headers.post['Authorization'] = store.state.token;
// }

// axios.defaults.baseURL = 'https://dev-panel.oz4.cn/';
axios.defaults.baseURL = 'https://panel.oz4.cn/';

// axios.defaults.baseURL = '/proxy';


// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // if (store.state.manageState) {
    //   // console.log('使用店铺token')
    //   config.headers.Authorization = 'bearer ' + store.state.shopInfo[store.state.manageState].token;
    // } else if (store.state.token) {
    //   // console.log('使用用户token')
    //   config.headers.Authorization = 'bearer ' + store.state.token;
    // }

    // config.headers.dataType = 'json'
    // config.headers.contentType = 'application/x-www-form-urlencoded;application/json'
    config.headers['content-type'] = 'application/x-www-form-urlencoded;application/json';
    return config;
  },
  err => Promise.reject(err),
);

// http response 拦截器
axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // alert('401');
          // 401 清除token信息并跳转到登录页面
          store.commit(types.LOGOUT);
          router.replace({
            name: 'signin',
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
          break;
        case 403:
          // alert('403');
          // 403 清除token信息 并且去登陆
          store.commit(types.LOGOUT);
          // dialog.toast({mes: '需要登陆后才能操作！' ,timeout: 1500})
          router.replace({
            name: 'signin',
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
          break;
      }
    }
    // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
    return Promise.reject(error.response.data);
  },
);


function makeFormData(obj, form_data) {
  const data = [];
  if (obj instanceof File) {
    data.push({ key: '', value: obj });
  } else if (obj instanceof Array) {
    for (var j = 0, len = obj.length;j < len;j++) {
      var arr = makeFormData(obj[j]);
      for (var k = 0, l = arr.length;k < l;k++) {
        var key = form_data ? j + arr[k].key : `[${j}]${arr[k].key}`;
        data.push({ key, value: arr[k].value });
      }
    }
  } else if (typeof obj === 'object') {
    for (var j in obj) {
      var arr = makeFormData(obj[j]);
      for (var k = 0, l = arr.length;k < l;k++) {
        var key = form_data ? j + arr[k].key : `.${j}${arr[k].key}`;
        data.push({ key, value: arr[k].value });
      }
    }
  } else {
    data.push({ key: '', value: obj });
  }
  if (form_data) {
    // 封装
    for (var i = 0, len = data.length;i < len;i++) {
      form_data.append(data[i].key, data[i].value);
    }
  } else {
    return data;
  }
}

export default {
  // fetchPost  请求方式
  fetchPost(url, params) {
    // console.log(params)
    // alert(JSON.stringify(params));
    return new Promise((resolve, reject) => {

      const data = {
        app_type: 'pc',
        app_type_name: 'pc',
      };

      if(params && params.token) {

      }else {
        data.token = store.state.token
      }
      // if (uni.getStorageSync('token'))
      //  data.token = 'token';
      // if (uni.getStorageSync('site_id'))
      //  data.site_id = 'site_id';
      // 参数合并
      Object.assign(data, params);
      const formDatas = new FormData();
      makeFormData(data, formDatas);
      
      axios.post(url, formDatas)
        .then((response) => {
          resolve(JSON.parse(response.data));
        }, (err) => {
          reject(err);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // GET 请求方式
  fetchGet(url, params) {
    // console.log(params)
    // alert(JSON.stringify(params));  b
    return new Promise((resolve, reject) => {
    
    const data = {
      app_type: 'pc',
      app_type_name: 'pc',
    };
    
    if(params && params.token) {

    }else {
      data.token = store.state.token
    }

      // if (uni.getStorageSync('token'))
      //  data.token = 'token';

      // if (uni.getStorageSync('site_id'))
      //  data.site_id = 'site_id';

      console.log(params);
      // 参数合并
      Object.assign(data, params);

      const formDatas = new FormData();
      makeFormData(data, formDatas);
      axios.get(url, {
        params: formDatas,
      })
        .then((response) => {
          resolve(JSON.parse(response.data));
        }, (err) => {
          reject(err);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
